<script setup>
// Properties
const props = defineProps({
  name: {
    type: String,
    required: true,
  },
  error: {
    type: String,
    default: null,
  },
  placeholder: {
    type: String,
    default: '',
  },
})

// Events
const emit = defineEmits(['custom-blur', 'custom-change'])

// References
const date = ref('')
const focused = ref(false)

// Handlers
function handleBlur() {
  // Emit custom event
  emit('custom-blur')
}

function handleFocus() {
  // Change state
  focused.value = true
}

function handleChange() {
  // Emit custom event
  emit('custom-change', props.name, date.value || undefined)
}
</script>

<template>
  <div class="input__date-wrapper">
    <span v-show="!focused && placeholder" class="input__date-placeholder">
      {{ placeholder }}
    </span>

    <input
      max="9999-12-31"
      type="date"
      :class="['input__date', { 'has-error': error }]"
      :title="placeholder"
      required
      @blur="handleBlur"
      @focus="handleFocus"
      @change="handleChange"
      v-model="date"
    />
  </div>
</template>
