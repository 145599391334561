<script setup>
import * as yup from 'yup'

// i18n
const { t } = useI18n()

// Properties
const props = defineProps({
  name: {
    type: String,
    required: true,
  },
  placeholder: {
    type: String,
    default: '',
  },
  modelValue: {
    type: String,
    default: '',
  },
  errorRequired: {
    type: String,
    default: '',
  },
  errorMinimum: {
    type: String,
    default: '',
  },
  errorInvalid: {
    type: String,
    default: '',
  },
})

// Schema
// prettier-ignore
const schema = yup.object({
  value: yup
    .string()
    .required(props.errorRequired || t('form.errors.phoneRequired'))
    .min(5, props.errorMinimum || t('form.errors.phoneMinimum')),
})

// Events
const emit = defineEmits([
  'custom-blur',
  'custom-input',
  'custom-validation',
  'update:modelValue',
])

// State
const phone = reactive({ value: '' })

// References
const error = ref('')
const format = ref(false)
const validation = ref(false)

// Handlers
// prettier-ignore
const handleFieldValidation = async () => {
  // Make it invalid in the `validations` object
  validation.value = false

  try {
    // Validate field
    await schema.validate(phone)

    // Field valid
    // Reset field error to its default value
    error.value = format.value ? '' : props.errorInvalid || t('form.errors.phoneInvalid')

    // Make it valid in the `validations` object
    validation.value = !!format.value
  } catch (err) {
    // Field invalid
    // Change its error message
    if (err) {
      error.value = err.message
    }
  }

  emit('custom-validation', props.name, {
    error: error.value,
    valid: validation.value,
  })

  return {
    error: error.value,
    valid: validation.value,
  }
}

const handleFormatValidation = (data) => {
  format.value = data.valid
  format.value
    ? emit('update:modelValue', data.number)
    : emit('update:modelValue', '')
}

const handleCountryChange = () => {
  // Reset state
  phone.value = ''

  // Reset references
  error.value = ''
  format.value = false
  validation.value = false

  // `v-model` event
  emit('update:modelValue', '')

  // Validation event
  emit('custom-validation', props.name, {
    error: error.value,
    valid: validation.value,
  })
}

defineExpose({
  handleFieldValidation,
})
</script>

<template>
  <ClientOnly>
    <vue-tel-input
      v-model="phone.value"
      :dropdown-options="{
        showDialCodeInList: true,
        showDialCodeInSelection: true,
        showFlags: true,
      }"
      :input-options="{
        type: 'tel',
        name,
        placeholder,
        showDialCode: false,
      }"
      mode="national"
      :preferred-countries="['US', 'gb']"
      :style-classes="['input__phone', { 'has-error': error }]"
      @blur="handleFieldValidation"
      @input="handleFieldValidation"
      @validate="handleFormatValidation"
      @country-changed="handleCountryChange"
    />
  </ClientOnly>
</template>

<style lang="scss">
@use 'sass:math';

// Utils
@import 'styles/utils/variables';

.input__phone {
  justify-content: center;
  border-radius: 32px;
  border: solid 1.5px #1d083a;

  .vti__selection {
    font-size: inherit;
  }

  .vti__dropdown {
    background: none !important;
    border-right: solid 1px rgba(#d0d0d1, 0.12);
    padding: 0;
    position: initial;
    margin-top: 21px;
    margin-bottom: 20px;
  }

  .vti__country-code {
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    letter-spacing: 0;
    color: inherit;
    margin: 0 10px;
  }

  .vti__dropdown-arrow {
    display: none;
  }

  .vti__dropdown-list {
    background-color: #200929;
    border: solid 1.5px transparent;
    border-radius: 15px;
    margin-top: 5px;
    padding: 10px;
    width: 100%;
    height: 200px;
    top: calc(100% + 5px);
    z-index: 10;
    overflow-y: overlay;
  }

  .vti__dropdown-item {
    font-size: 12px;
    font-weight: $font-weight-4G;
    line-height: math.div(16, 12);
    letter-spacing: 0.27em;
    text-transform: uppercase;
    color: rgba($brand-white, 0.5);
    background-color: transparent;
    padding: 20px 25px;
    display: flex;
    align-items: center;
    width: 100%;

    strong {
      color: $brand-white;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-right: auto;
    }

    * + * {
      margin-left: 5px;
    }

    &.last-preferred {
      position: relative;
      border-bottom: none;
      margin-bottom: 10px;

      &::after {
        position: absolute;
        top: 100%;
        left: 0;
        content: '';
        display: block;
        width: 100%;
        height: 1px;
        margin-top: 5px;
        background-color: rgba($brand-white, 0.15);
      }
    }

    .vti__flag {
      margin-right: 7px;
    }
  }

  .vti__dropdown-list li:hover {
    background-color: #c80afb;
    border-radius: 10px;
  }

  .vti__flag {
    // background-clip: content-box;
    // border: solid 1px rgba($brand-white, 0.3);
    // border-radius: 50%;
    box-shadow: unset;
    margin: 0;
    // width: 18px;
    // height: 18px;
    flex-shrink: 0;
    // overflow: hidden;
    display: none;
  }

  .vti__input {
    margin-left: 10px;
    line-height: 58px;
  }

  .vti__input::placeholder {
    transform: translateY(-1px);
  }
}
</style>
